html,
body,
div#root,
div.App {
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
}
.map-marker-label {
  font-weight: 900;
  text-shadow: -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff, 1px 1px 0 #fff;
  font-size: 0.7rem;
  position: absolute;
  top: -15px;
  white-space: nowrap;
}
